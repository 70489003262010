require('./image/solros-background.png');
require('./image/solros-logo.png');
require('./image/solros-icon.svg');

require('./image/contact-background.png');

require('./image/facebook-icon.svg');
require('./image/linkedin-icon.svg');
require('./image/instagram-icon.svg');
require('./image/twitter-icon.svg');

require('./image/favicon/android-chrome-192x192.png');
require('./image/favicon/android-chrome-256x256.png');
require('./image/favicon/apple-touch-icon.png');
require('./image/favicon/favicon-32x32.png');
require('./image/favicon/favicon-16x16.png');
require('./image/favicon/mstile-150x150.png');
require('./image/favicon/site.webmanifest');
require('./image/favicon/safari-pinned-tab.svg');
